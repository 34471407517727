import './App.css';
import ashwin1 from './assets/Ashwin_1.png'
import ashwin2 from './assets/Ashwin_2.png'
import ashwin3 from './assets/Ashwin_3.png'
import ashwin4 from './assets/Ashwin_4.png'
import ashwin5 from './assets/Ashwin_5.png'
import ashwin6 from './assets/Ashwin_6.png'
import ashwin7 from './assets/Ashwin_7.png'
import ashwin8 from './assets/Ashwin_8.jpg'
import ashwin9 from './assets/Ashwin_9.jpg'
import ashwin10 from './assets/Ashwin_10.jpg'
import ashwin11 from './assets/Ashwin_11.jpg'

import ImageWrapper from './components/imageWrapper/imageWrapper'

function App() {

  function height() {
    return Math.floor(Math.random()*window.innerHeight*0.5336)+15
  }

  function width() {
    return Math.floor(Math.random()*window.innerWidth*0.2604)+15
  }

  return (
    <div className='wrapper'>
      <ImageWrapper image={ashwin1} height={height()} width={width()}/>
      <ImageWrapper image={ashwin2} height={height()} width={width()}/>
      <ImageWrapper image={ashwin3} height={height()} width={width()}/>
      <ImageWrapper image={ashwin4} height={height()} width={width()}/>
      <ImageWrapper image={ashwin5} height={height()} width={width()}/>
      <ImageWrapper image={ashwin6} height={height()} width={width()}/>
      <ImageWrapper image={ashwin7} height={height()} width={width()}/>
      <ImageWrapper image={ashwin8} height={height()} width={width()}/>
      <ImageWrapper image={ashwin9} height={height()} width={width()}/>
      <ImageWrapper image={ashwin10} height={height()} width={width()}/>
      <ImageWrapper image={ashwin11} height={height()} width={width()}/>
      <h1>
        Hello my name is Ashwin! I fully endorse the contents of this website
      </h1>
    </div>
    
  );
}

export default App;
