import { useRef } from "react";
import './imageWrapper.css'

const ImageWrapper = (props) => {
    const windowHeight = window.innerHeight;
    const windowWidth = window.innerWidth;
    const imgHeight = props.height;
    const imgWidth = props.width;
    const diag = Math.ceil(Math.sqrt(Math.pow(imgHeight, 2) + Math.pow(imgWidth, 2)))
    let link = 'https://www.linkedin.com/in/ashwin-vasan-b0b99b183/'

    const ChipStyles = useRef({
        position: 'absolute',
        top: Math.floor(Math.random()*(windowHeight-imgHeight-5 - (diag-imgHeight)/2)),
        left: Math.floor(Math.random()*(windowWidth-imgWidth - 5 - (diag-imgWidth)/2)),
        margin: 0,
        padding: 0,
        animationDuration:  (Math.floor(Math.random()*(10000)+500)+ 'ms')
    });

    const ImageStyle = {
        height: imgHeight,
        width: imgWidth,
        objectFit: 'fill',
        margin: 0,
        padding: 0,
        zIndex: Math.floor(Math.random()*(13))
    }

    function animation() {
        const random = Math.random()
        if (random < 0.2) {
            return 'zoom-spin-container';
        } else if (random < 0.6) {
            return 'zoom-spin-container';
        } else {
            return 'zoom-container';
        }

    }

    return (
        <div style={ChipStyles.current} className={animation()}>
            {/* Herro agian {props.yourName} */}
            <a href={link} target='_blank'>
                <img src={props.image} style={ImageStyle} alt="ASHWIN"></img>
            </a>
        </div>
        
    )
}

export default ImageWrapper;